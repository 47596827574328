import Swiper from 'swiper';

$(function() {
	create_swiper_home();
});

function create_swiper_home() {
	if ($('#swiper-slides').length > 0) {
		var swiper = new Swiper('#swiper-slides', {
			// Init swiper manually
			init: false,

			// Optional parameters
			direction: 'horizontal',
			loop: true,
			speed: 1000,

			// Transition effect
			effect: 'fade',

			// Autoplay
			autoplay: {
				disableOnInteraction: false,
				delay: 6000
			},

			// If we need pagination
			pagination: {
				el: '#swiper-slides .swiper-pagination',
				clickable: true
			},

			// Navigation arrows
			navigation: {
				nextEl: '#swiper-slides .swiper-button-next',
				prevEl: '#swiper-slides .swiper-button-prev'
			}
		});
		// Events
		swiper.on('slideChangeTransitionEnd', function() {
            var activeSlide = $(swiper.slides[swiper.activeIndex]);
            activeSlide.find('.content').addClass('show');
            activeSlide.find('.title').addClass('show');
            var timeoutLink = 1000;
			if (activeSlide.find('.excerpt').length > 0) {
                timeoutLink = 2000;
				setTimeout(function() {
					activeSlide.find('.excerpt').addClass('show');
				}, 1000);
            }
			if (activeSlide.find('.link').length > 0) {
				setTimeout(function() {
					activeSlide.find('.link').addClass('show');
				}, timeoutLink);
			}            
		});
		swiper.on('slideChangeTransitionStart', function() {
            var activeSlide = $(swiper.slides[swiper.activeIndex]);
            activeSlide.find('.content').removeClass('show');
            activeSlide.find('.title').removeClass('show');
            if (activeSlide.find('.excerpt').length > 0) {
                activeSlide.find('.excerpt').removeClass('show');
            }
            if (activeSlide.find('.link').length > 0) {
                activeSlide.find('.link').removeClass('show');
            }            
		});
		// Init swiper
		swiper.init();
	}
}
